<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

import VueSession from 'vue-session';
Vue.use(VueSession);


/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: "sds",
      tryingToLogIn: false,
      isAuthError: false,
      
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    if(localStorage.getItem("vuex")) {
      localStorage.removeItem("vuex");
    }
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
                                                                      
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        // const requestOptions = {
        //     method: 'POST',
        //    // headers: { 'Content-Type': 'application/json' },
        //     body: { email, password }
        // };

          if (email && password) {
             let formData = new FormData();  
              formData.append('emailID', email);
              formData.append('password', password);

                  this.axios.post('login', formData)
                  
                  .then((result)=>{
                    let status = result.data.status;
                    if(status==200){
                       //  console.log(result.data.user);
                            if (result.data.user) {
                             
                              // this.$session.start();
                               // this.$session.set('token', JSON.stringify(result.data.token));

                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                                 localStorage.setItem('user', JSON.stringify(result.data.user));
                                 localStorage.setItem('loginType',JSON.stringify(result.data.user.loginType));
                                 //sessionStorage.setItem("authUser", JSON.stringify(result.data.user));
                                //commit('loginSuccess', user);
                                ///router.push('/');


                                  this.$router.push('/');
                            }
                    }else{
                        this.isAuthError = true;
                        this.authError = result.data.message;
                    }
                   


                  }).catch(function (error) {
                       alert(error);
                        
                    });
          }
      }
    },

    
  },
};
</script>

<template>
  <div>

    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="60"
                  class="logo logo-dark" style="object-fit:cover;"
                />
                <img
                  src="@/assets/images/logo.png"
                  alt
                  height="60"
                  class="logo logo-light"
                  style="object-fit:cover;"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary" style="color: #F3766A;">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to CloudKitch.</p>
                </div>
                <div style="text-align: center;">
                    <router-link to="/login"><button class="btn btn-themeYellow mb-3" style="width: 67%;"> Super Admin Login </button></router-link>
                    <router-link to="/restaurantLogin"><button class="btn btn-themeYellow mb-3" style="width: 67%;"> Restaurant Login </button></router-link>
                     <router-link to="/corporateLogin"><button class="btn btn-themeYellow mb-3" style="width: 67%;"> Corporate Login </button></router-link>
                     <router-link to="/principleLogin"><button class="btn btn-themeYellow mb-3" style="width: 67%;"> Principal Login </button></router-link>
                </div>
              
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                © {{ new Date().getFullYear() }} CloudKitch Pvt Ltd. 
              </p>
              <!-- Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Themesbrand -->
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.authentication-bg {
    background-color: rgb(216 226 245 / 25%)  !important;
}
</style>
